.title{
   font-size: 30px;
   font-weight: bold;
   color: #265BA8;
}
.container{
    padding: 30px;
}
.wrapLangVid{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}
.buttonLanguage{
    width: 285px;
    height: 50px;
    background-color: #265BA8;
    color: white;
    font-size: 20px;
    border: none;
    border-radius: 10px;
}
.linkVideo{
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: #265BA8;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 8px;
    padding-top: 3px;
    padding-bottom: 3px;

}
.triangle{
  width: 0;
  height: 0;
  border: 13px solid transparent;
  border-top: 0;
  border-bottom: 25px solid white;
  transform: rotate(90deg);
  
}
.text{
    background-color: white;
    color: black;
    padding: 20px;
    border-radius: 20px;
}
.finish{
    margin-top: 15px;
    background-color: #265BA8;
    color: white;
    font-size: 20px;
    height: 68px;
    width: 122px;
    border: none;
    border-radius: 20px 0px 20px 0px;
    cursor: pointer;
}