.title{
    display: flex;
    justify-content: center;
    font-size: 40px;
    font-weight: bold;
    color: white;
    padding-top: 50px;
    text-align: center;
    direction: rtl;
}
.button{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.link{
    background-color: white;
    border-radius: 20px 0px 20px 0px;
    border: none;
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
    color: black;
    height: 60px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 10px; */
}
.link:active{
    opacity: 0.5;
}
.buttonDiv{
    display: flex;
    justify-content: center;
    padding-top: 25px;
}
.buttonName{
    background-color: #2E8BFF;
    color: white;
    font-weight: bold;
    border-radius: 20px 0px 20px 0px;
    width: 280px;
    height: 60px;
    font-size: 20px;
    border: none;
    cursor: pointer;
}
.buttonName:active{
    opacity: 0.5;
}
.names{
    padding: 10px;
    background-color: #2E8BFF;
    color: white;
    font-weight: bold;
    border-radius: 20px 0px 20px 0px;
    width: 280px;
    min-height: 50px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    direction: rtl;
}
.nameDiv{
    padding-top: 15px;
    direction: rtl;
}
.allNames{
    display: flex;
    justify-content: center;
}
.footer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    background-color: #FEA702;
    /* background-color: #2E8BFF; */
    color: black;
    font-weight: bold;
    direction: rtl;
}
.desk{
    width: 250px;
    min-height: 10px;
    direction: rtl;
    color: white;
    font-size: 20px;
    font-weight: bold;
}
.input{
    width: 260px;
    height: 50px;
    border-radius: 25px;
    direction: rtl;
    font-size: 20px;
    padding-right: 20px;
    margin-top: 10px;
}
.inputContainer{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 25px;
}
.buttonContainer{
    display: flex;
    justify-content: center;
    padding-top: 25px;
}
.img{
    position: absolute;
    z-index: 3;
}
.bgLink{
    top: 0;
    position: fixed;
    left: 0;
    right: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
.bgNote{
    padding: 40px 35px 30px 35px;
    border-radius: 20px;
    background-color:#FEA702;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;
}
.noteLink{
    padding-top: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 20px;
    color: #265BA8;
}